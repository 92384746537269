import {createReducer, on} from '@ngrx/store';
import * as packageGroupActions from '../actions/packageGroup.actions';
import {packageGroupDto} from "../models/packageGroup.entities";

export interface PackageGroupState {
  packageGroups: packageGroupDto[];
  error: any;
}

export const initialState: PackageGroupState = {
  packageGroups: [],
  error: null,
};

export const packageGroupReducer = createReducer(
  initialState,
  on(packageGroupActions.loadPackagesGroupsSuccess, (state, { packageGroups }) => ({ ...state, packageGroups:[...packageGroups] })),
  on(packageGroupActions.loadPackagesGroupsFailure, (state, { error }) => ({ ...state, error })),
  on(packageGroupActions.addPackageGroupSuccess, (state, { packageGroup }) => ({ ...state, packageGroups: [...state.packageGroups, packageGroup] })),
  on(packageGroupActions.addPackageGroupFailure, (state, { error }) => ({ ...state, error })),
  on(packageGroupActions.updatePackageGroupSuccess, (state, { packageGroup }) => ({
    ...state,
    packageGroup: state.packageGroups.map(u => (u.id === packageGroup.id ? packageGroup : u))
  })),
  on(packageGroupActions.updatePackageGroupFailure, (state, { error }) => ({ ...state, error })),
  on(packageGroupActions.deletePackageGroupSuccess, (state, { id }) => ({
    ...state,
    packageGroup: state.packageGroups.filter(packageGroup => packageGroup.id !== id)
  })),
  on(packageGroupActions.deletePackageGroupFailure, (state, { error }) => ({ ...state, error }))
);
