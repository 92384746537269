import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SubContractService} from '../services/subContract.service';
import * as SubContractReportActions from '../actions/subContractReport.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class SubContractReportEffects {
  constructor(private actions$: Actions, private subContractService: SubContractService) {}

  loadPlanReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubContractReportActions.loadSubContractReport),
      mergeMap(action =>
        this.subContractService.loadSubContractReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel).pipe(
          map(subContractReport => SubContractReportActions.loadSubContractReportSuccess({ subContractReport })),
          catchError(error => of(SubContractReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );

}
