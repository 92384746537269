import {createReducer, on} from '@ngrx/store';
import * as SubContractReportActions from '../actions/subContractReport.actions';

export interface SubContractReportState {
  subContractTimeReport: any[];

  error: any;
}

export const initialState: SubContractReportState = {
  subContractTimeReport: [],

  error: null,
};

export const subContractReportReducer = createReducer(
  initialState,

  on(SubContractReportActions.loadSubContractReportSuccess, (state, {subContractReport}) => ({
    ...state,
    subContractTimeReport: [...subContractReport]
  })),
);
