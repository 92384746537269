import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ContractsDto} from "../models/contract.model";

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  apiUrl = `${environment.myBackend}/sales/contracts/contract`;

  constructor(private http: HttpClient) {

  }

  addUser(value: ContractsDto): Observable<ContractsDto> {
    return this.http.put<ContractsDto>(this.apiUrl, value);
  }

  getUser(filters: any): Observable<ContractsDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    console.log(qs, filters)
    if (filters && filters.viewType == "Advanced") {
      return this.http.get<ContractsDto[]>(`${this.apiUrl}/advanced?${qs}`);
    } else {
      return this.http.get<ContractsDto[]>(`${this.apiUrl}?${qs}`);
    }
  }

  updateUser(id: number, value: ContractsDto): Observable<ContractsDto> {
    return this.http.patch<ContractsDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete<ContractsDto>(`${this.apiUrl}/${id}`);
  }
}
