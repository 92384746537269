import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ClientService} from '../../services/client.service';
import * as ClientActions from '../actions/client.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class ClientEffects {
  constructor(private actions$: Actions, private clientService: ClientService) {}

  loadClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadClients),
      mergeMap(action =>
        this.clientService.getClient(action).pipe(
          map(clients => ClientActions.loadClientsSuccess({ clients })),
          catchError(error => of(ClientActions.loadClientsFailure({ error })))
        )
      )
    )
  );

  addClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.addClient),
      mergeMap(action =>
        this.clientService.addClient(action.client).pipe(
          map(client => ClientActions.addClientSuccess({ client })),
          catchError(error => of(ClientActions.addClientFailure({ error })))
        )
      )
    )
  );

  updateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.updateClient),
      mergeMap(action =>
        this.clientService.updateClient(action.id, action.client).pipe(
          map(client => ClientActions.updateClientSuccess({ client })),
          catchError(error => of(ClientActions.updateClientFailure({ error })))
        )
      )
    )
  );

  deleteClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClient),
      mergeMap(action =>
        this.clientService.deleteClient(action.id).pipe(
          map(() => ClientActions.deleteClientSuccess({ id: action.id })),
          catchError(error => of(ClientActions.deleteClientFailure({ error })))
        )
      )
    )
  );
}
