import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SalesPackageDto, UpdateSalesPackageDto} from "../store/models/package.entities";

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  apiUrl = `${environment.myBackend}/sales/billing/salesPackage`;

  constructor(private http: HttpClient) {

  }

  addPackage(value: SalesPackageDto): Observable<SalesPackageDto> {
    return this.http.put<SalesPackageDto>(this.apiUrl, value);
  }

  getPackage(filters: any): Observable<SalesPackageDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    console.log(qs, filters)
    return this.http.get<SalesPackageDto[]>(`${this.apiUrl}?${qs}`);

  }

  updatePackage(id: number, value: UpdateSalesPackageDto): Observable<SalesPackageDto> {
    return this.http.patch<SalesPackageDto>(`${this.apiUrl}/${id}`, value)
  }

  deletePackage(id: number): Observable<any> {
    return this.http.delete<SalesPackageDto>(`${this.apiUrl}/${id}`);
  }
}
