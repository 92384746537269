import {createReducer, on} from '@ngrx/store';
import * as ContractsActions from '../actions/contract.actions';
import {ContractsDto} from "../models/contract.model";

export interface ContractState {
  contracts: ContractsDto[];
  error: any;
}

export const initialState: ContractState = {
  contracts: [],
  error: null,
};

export const contractReducer = createReducer(
  initialState,
  on(ContractsActions.loadContractsSuccess, (state, { contracts }) => ({ ...state, contracts:[...contracts] })),
  on(ContractsActions.loadContractsFailure, (state, { error }) => ({ ...state, error })),
  on(ContractsActions.addContractSuccess, (state, { contract }) => ({ ...state, contracts: [...state.contracts, contract] })),
  on(ContractsActions.addContractFailure, (state, { error }) => ({ ...state, error })),
  on(ContractsActions.updateContractSuccess, (state, { contract }) => ({
    ...state,
    contracts: state.contracts.map(u => (u.id === contract.id ? contract : u))
  })),
  on(ContractsActions.updateContractFailure, (state, { error }) => ({ ...state, error })),
  on(ContractsActions.deleteContractSuccess, (state, { id }) => ({
    ...state,
    contracts: state.contracts.filter(user => user.id !== id)
  })),
  on(ContractsActions.deleteContractFailure, (state, { error }) => ({ ...state, error }))
);
