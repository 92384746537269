import {createReducer, on} from '@ngrx/store';
import * as UserActions from '../actions/client.actions';
import {ClientDto} from "../models/client.model";

export interface ClientState {
  clients: ClientDto[];
  error: any;
}

export const initialState: ClientState = {
  clients: [],
  error: null,
};

export const clientReducer = createReducer(
  initialState,
  on(UserActions.loadClientsSuccess, (state, { clients }) => ({ ...state, clients:[...clients] })),
  on(UserActions.loadClientsFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.addClientSuccess, (state, { client }) => ({ ...state, clients: [...state.clients, client] })),
  on(UserActions.addClientFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.updateClientSuccess, (state, { client }) => ({
    ...state,
    clients: state.clients.map(u => (u.id === client.id ? client : u))
  })),
  on(UserActions.updateClientFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.deleteClientSuccess, (state, { id }) => ({
    ...state,
    clients: state.clients.filter(client => client.id !== id)
  })),
  on(UserActions.deleteClientFailure, (state, { error }) => ({ ...state, error }))
);
