import {createReducer, on} from '@ngrx/store';
import * as SubContractsActions from '../actions/subContract.actions';
import {SubContractDto} from "../models/subContract.model";

export interface SubContractState {
  subContracts: SubContractDto[];
  error: any;
}

export const initialState: SubContractState = {
  subContracts: [],
  error: null,
};

export const subContractReducer = createReducer(
  initialState,
  on(SubContractsActions.loadSubContractsSuccess, (state, {subContracts}) => ({...state,subContracts:subContracts})),
  on(SubContractsActions.loadSubContractsFailure, (state, { error }) => ({ ...state, error })),
  on(SubContractsActions.addSubContractSuccess, (state, { newSubContract }) => ({ ...state, subContracts: [...state.subContracts, newSubContract] })),
  on(SubContractsActions.addSubContractFailure, (state, { error }) => ({ ...state, error })),
  on(SubContractsActions.updateSubContractSuccess, (state, { updateContract }) => ({
    ...state,
    subContracts: state.subContracts.map(u => (u.id === updateContract.id ? updateContract : u))
  })),
  on(SubContractsActions.updateSubContractFailure, (state, { error }) => ({ ...state, error })),
  on(SubContractsActions.deleteSubContractSuccess, (state, { id }) => ({
    ...state,
    subContracts: state.subContracts.filter(subContract => subContract.id !== id)
  })),
  on(SubContractsActions.deleteSubContractFailure, (state, { error }) => ({ ...state, error }))
);
