import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SubContractDto} from "../models/subContract.model";

@Injectable({
  providedIn: 'root'
})
export class SubContractService {
  apiUrl = `${environment.myBackend}/sales/contracts/subcontract`;

  constructor(private http: HttpClient) {

  }

  addSubContract(value: SubContractDto): Observable<SubContractDto> {
    return this.http.put<SubContractDto>(this.apiUrl, value);
  }

  getSubContract(filters: any): Observable<SubContractDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    if (filters && (filters.viewType == "Advanced" || filters.viewType == "Detailed")) {
      return this.http.get<SubContractDto[]>(`${this.apiUrl}/advanced?${qs}`);
    } else {
      return this.http.get<SubContractDto[]>(`${this.apiUrl}?${qs}`);
    }


  }

  updateSubContract(id: number, value: SubContractDto): Observable<SubContractDto> {
    return this.http.patch<SubContractDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteSubContract(id: number): Observable<any> {
    return this.http.delete<SubContractDto>(`${this.apiUrl}/${id}`);
  }

  loadSubContractReport(startDate: string, endDate: string, firstLevel: string, secondLevel: string, thirdLevel: string): Observable<any[]> {
    let qs = "?startDate=" + startDate + "&endDate=" + endDate + "&firstLevel=" + firstLevel + "&secondLevel=" + secondLevel + "&thirdLevel=" + thirdLevel

    return this.http.get<any[]>(`${this.apiUrl}/subContractReport${qs}`);
  }
}
