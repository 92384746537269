import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PackageGroupActions from '../actions/packageGroup.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PackageGroupService} from "../../services/packageGroup.service";

@Injectable()
export class PackageGroupEffects {
  constructor(private actions$: Actions, private packageGroupService: PackageGroupService) {}

  loadPackageGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageGroupActions.loadPackagesGroups),
      mergeMap(action =>
        this.packageGroupService.getPackageGroup(action).pipe(
          map(packageGroups => PackageGroupActions.loadPackagesGroupsSuccess({ packageGroups })),
          catchError(error => of(PackageGroupActions.loadPackagesGroupsFailure({ error })))
        )
      )
    )
  );

  addPackageGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageGroupActions.addPackageGroup),
      mergeMap(action =>
        this.packageGroupService.addPackageGroup(action.packageGroup).pipe(
          map(packageGroup => PackageGroupActions.addPackageGroupSuccess({ packageGroup })),
          catchError(error => of(PackageGroupActions.addPackageGroupFailure({ error })))
        )
      )
    )
  );

  updatePackageGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageGroupActions.updatePackageGroup),
      mergeMap(action =>
        this.packageGroupService.updatePackageGroup(action.id, action.packageGroup).pipe(
          map(packageGroup => PackageGroupActions.updatePackageGroupSuccess({ packageGroup })),
          catchError(error => of(PackageGroupActions.updatePackageGroupFailure({ error })))
        )
      )
    )
  );

  deletePackageGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageGroupActions.deletePackageGroup),
      mergeMap(action =>
        this.packageGroupService.deletePackageGroup(action.id).pipe(
          map(() => PackageGroupActions.deletePackageGroupSuccess({ id: action.id })),
          catchError(error => of(PackageGroupActions.deletePackageGroupFailure({ error })))
        )
      )
    )
  );
}
