import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";

import {PackageEffects} from './Billing/store/effects/package.effects'
import {ClientEffects} from './Billing/store/effects/client.effects'
import {PackageGroupEffects} from './Billing/store/effects/packageGroup.effects'
import {ContractEffects} from "./Contract/store/effects/contract.effects";
import {SubContractEffects} from "./Contract/store/effects/subContract.effects";

import {packageReducer} from './Billing/store/reducers/package.reducer'
import {clientReducer} from './Billing/store/reducers/client.reducer'
import {packageGroupReducer} from './Billing/store/reducers/packageGroup.reducer'
import {contractReducer} from "./Contract/store/reducers/contract.reducer";
import {subContractReducer} from "./Contract/store/reducers/subContract.reducer";
import {authGuard} from "../../core/guards/auth.guard";
import * as Permissions from '../../features/admin/Position/permissions'
import {subContractReportReducer} from "./Contract/store/reducers/subContractReport.reducer";
import {SubContractReportEffects} from "./Contract/store/effects/subContractReport.effects";
import {timesheetReportReducer} from "../pms/Timesheet/store/reducers/timesheetReport.reducer";
import {TimesheetReportEffects} from "../pms/Timesheet/store/effects/timesheetReport.effects";
import {leaveReducer} from "../hr/Leave/store/reducers/leave.reducer";
import {holidayReducer} from "../hr/org/store/reducers/holiday.reducer";
import {LeaveEffects} from "../hr/Leave/store/effects/leave.effects";
import {HolidayEffects} from "../hr/org/store/effects/holiday.effects";

const routes: Routes = [
  {
    path: 'contract',
    loadChildren: () => import('./Contract/contract-routing.module').then(m => m.SalesContractRoutingModule),
    canActivate: [authGuard],
    data: {permission: Permissions.FEATURE_SALES_CONTRACT}

  },
  {
    path: 'billing',
    loadChildren: () => import('./Billing/billing-routing.module').then(m => m.SalesBillingRoutingModule),
    canActivate: [authGuard],
    data: {permission: Permissions.FEATURE_SALES_BILLING}

  },

];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    StoreModule.forFeature('clients', clientReducer),
    StoreModule.forFeature('packages', packageReducer),
    StoreModule.forFeature('packageGroups', packageGroupReducer),
    StoreModule.forFeature('contracts', contractReducer),
    StoreModule.forFeature('subContracts', subContractReducer),
    StoreModule.forFeature('subContractReport', subContractReportReducer),
    StoreModule.forFeature('timesheetReport', timesheetReportReducer),
    StoreModule.forFeature('leaves', leaveReducer),
    StoreModule.forFeature('holidays', holidayReducer),
    EffectsModule.forFeature([
      ClientEffects,
      PackageEffects,
      PackageGroupEffects,
      ContractEffects,
      SubContractEffects,
      SubContractReportEffects,
      TimesheetReportEffects,
      LeaveEffects,
      HolidayEffects,
    ])
  ],
  exports: [RouterModule]
})
export class SalesRoutingModule {
}
