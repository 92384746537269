import {createReducer, on} from '@ngrx/store';
import * as PackageActions from '../actions/package.actions';
import {SalesPackageDto} from "../models/package.entities";

export interface PackageState {
  salesPackages: SalesPackageDto[];
  error: any;
}

export const initialState: PackageState = {
  salesPackages: [],
  error: null,
};

export const packageReducer = createReducer(
  initialState,
  on(PackageActions.loadPackagesSuccess, (state, { salesPackages }) => ({ ...state, salesPackages:[...salesPackages] })),
  on(PackageActions.loadPackagesFailure, (state, { error }) => ({ ...state, error })),
  on(PackageActions.addPackageSuccess, (state, { salesPackage }) => ({ ...state, salesPackages: [...state.salesPackages, salesPackage] })),
  on(PackageActions.addPackageFailure, (state, { error }) => ({ ...state, error })),
  on(PackageActions.updatePackageSuccess, (state, { salesPackage }) => ({
    ...state,
    salesPackages: state.salesPackages.map(u => (u.id === salesPackage.id ? salesPackage : u))
  })),
  on(PackageActions.updatePackageFailure, (state, { error }) => ({ ...state, error })),
  on(PackageActions.deletePackageSuccess, (state, { id }) => ({
    ...state,
    salesPackage: state.salesPackages.filter(salesPackage => salesPackage.id !== id)
  })),
  on(PackageActions.deletePackageFailure, (state, { error }) => ({ ...state, error }))
);
