import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {packageGroupDto} from "../store/models/packageGroup.entities";

@Injectable({
  providedIn: 'root'
})
export class PackageGroupService {
  apiUrl = `${environment.myBackend}/sales/billing/packageGroup`;

  constructor(private http: HttpClient) {

  }

  addPackageGroup(value: packageGroupDto): Observable<packageGroupDto> {
    return this.http.put<packageGroupDto>(this.apiUrl, value);
  }

  getPackageGroup(filters: any): Observable<packageGroupDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    console.log(qs, filters)
    return this.http.get<packageGroupDto[]>(`${this.apiUrl}?${qs}`);

  }

  updatePackageGroup(id: number, value: packageGroupDto): Observable<packageGroupDto> {
    return this.http.patch<packageGroupDto>(`${this.apiUrl}/${id}`, value)
  }

  deletePackageGroup(id: number): Observable<any> {
    return this.http.delete<packageGroupDto>(`${this.apiUrl}/${id}`);
  }
}
