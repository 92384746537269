import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PackageActions from '../actions/package.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PackageService} from "../../services/package.service";

@Injectable()
export class PackageEffects {
  constructor(private actions$: Actions, private packageService: PackageService) {}

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageActions.loadPackages),
      mergeMap(action =>
        this.packageService.getPackage(action).pipe(
          map(salesPackages => PackageActions.loadPackagesSuccess({ salesPackages })),
          catchError(error => of(PackageActions.loadPackagesFailure({ error })))
        )
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageActions.addPackage),
      mergeMap(action =>
        this.packageService.addPackage(action.salesPackage).pipe(
          map(salesPackage => PackageActions.addPackageSuccess({ salesPackage })),
          catchError(error => of(PackageActions.addPackageFailure({ error })))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageActions.updatePackage),
      mergeMap(action =>
        this.packageService.updatePackage(action.id, action.salesPackage).pipe(
          map(salesPackage => PackageActions.updatePackageSuccess({ salesPackage })),
          catchError(error => of(PackageActions.updatePackageFailure({ error })))
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageActions.deletePackage),
      mergeMap(action =>
        this.packageService.deletePackage(action.id).pipe(
          map(() => PackageActions.deletePackageSuccess({ id: action.id })),
          catchError(error => of(PackageActions.deletePackageFailure({ error })))
        )
      )
    )
  );
}
