import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ClientDto} from "../store/models/client.model";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  apiUrl = `${environment.myBackend}/sales/billing/client`;

  constructor(private http: HttpClient) {

  }

  addClient(value: ClientDto): Observable<ClientDto> {
    return this.http.put<ClientDto>(this.apiUrl, value);
  }

  getClient(filters: any): Observable<ClientDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    console.log(qs, filters)
    return this.http.get<ClientDto[]>(`${this.apiUrl}?${qs}`);

  }

  updateClient(id: number, value: ClientDto): Observable<ClientDto> {
    return this.http.patch<ClientDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteClient(id: number): Observable<any> {
    return this.http.delete<ClientDto>(`${this.apiUrl}/${id}`);
  }
}
